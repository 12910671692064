import React from 'react';
import { Box, Typography } from '@mui/material';
import BusinessSkillRadarChart from './BusinessSkillRadarChart';
import LanguageSkillRadarChart from './LanguageSkillRadarChart';
import FrameworkHorizontalBarChart from './FrameworkHorizontalBarChart';
import WorkDistributionChart from './WorkDistributionChart';
import AdditionalExperienceChart from './AdditionalExperienceChart';

function Skill() {
    return (
        <Box id="skill" sx={{ textAlign: 'center', marginBottom:15 }}>
            <Typography variant="h3" gutterBottom>
                SKILL
            </Typography>

            {/* 3つのグラフを横並びで表示 */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    gap: 4,
                    margin: 2,
                }}
            >
                {/* 業務範囲 */}
                <Box sx={{ minWidth: 300, flex: 1, textAlign: 'center' }}>
                    <Typography variant="h5" gutterBottom>
                        業務範囲
                    </Typography>
                    <BusinessSkillRadarChart />
                </Box>

                {/* プログラミング言語/DB言語 */}
                <Box sx={{ minWidth: 300, flex: 1, textAlign: 'center', marginLeft:4, marginRight:4 }}>
                    <Typography variant="h5" gutterBottom>
                        プログラミング言語/DB言語
                    </Typography>
                    <LanguageSkillRadarChart />
                </Box>

                {/* フレームワーク/DBの経験年数 */}
                <Box sx={{ minWidth: 300, flex: 1, textAlign: 'center' }}>
                    <Typography variant="h5" gutterBottom>
                        フレームワーク/DBの経験年数
                    </Typography>
                    <FrameworkHorizontalBarChart />
                </Box>
            </Box>
            
            {/* 2つのグラフを横並びで表示 */}
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'flex-start', 
                flexWrap: 'wrap',
                gap: 4,
                margin: 4 
            }}>
                <Box sx={{ minWidth:300, flex:1, textAlign: 'center'}}>
                    {/* 業務割合 */}
                    <Typography variant="h5" gutterBottom sx={{ marginTop: 4 }}>
                        業務割合
                    </Typography>
                    <WorkDistributionChart />
                </Box>

                {/* その他 */}
                <Box sx={{ minWidth:300, flex:1, textAlign: 'center', marginLeft:4, marginRight:4 }}>
                    <Typography variant="h5" gutterBottom sx={{ marginTop: 4 }}>
                        その他
                    </Typography>
                    <AdditionalExperienceChart />
                </Box>
                {/* 空のボックス（レイアウトを整えるため） */}
                <Box sx={{ minWidth: 300, flex: 1, visibility: 'hidden' }}>
                    {/* 透明な要素としてのダミー */}
                </Box>
            </Box>
        </Box>
    );
}

export default Skill;
