import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

function LanguageSkillRadarChart() {
    const data = {
        labels: ['JavaScript', 'Python', 'C#', 'VB', 'SQL Server', 'Oracle'],
        datasets: [
            {
                label: 'スキルレベル',
                data: [5, 4, 5, 4, 5, 3],
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
            },
        ],
    };
    const options = {
        scales: {
            r: {
                min: 1,
                max: 5,
                ticks: {
                    stepSize: 1,
                    callback: function(value: number | string) {
                    const labels = ['E', 'D', 'C', 'B', 'A'];
                    if (typeof value === 'number' && value >= 1 && value <= 5) {
                        return labels[value - 1];
                    }
                    return value;
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'top' as const,
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem: any) => {
                        const levelDescriptions = [
                        'E: 学習した経験がある。',
                        'D: 実務を通して学んだ経験がある。',
                        'C: 上司の指導のもとで業務を進められる。',
                        'B: 業務を単独で遂行できる。',
                        'A: 業務を一人で完遂できる。課題を発見し解決できる。後輩への指導が可能。',
                        ];
                        const index = tooltipItem.raw - 1;
                        return levelDescriptions[index];
                    },
                },
            },
        },
        
    };
    
    return <Radar data={data} options={options} />;
}

export default LanguageSkillRadarChart;
