export enum Routes {
    Home = '/',
    About = '/about',
    Affiliate = '/affiliate',
    ChatAdviser = '/chat-adviser',
    Carrier = '/carrier',
    Achievements = '/achievements',
    Skill = '/skill',
    Services = '/services',
    Contact = '/contact',
}


