import React from 'react';
import { Typography, Box } from '@mui/material';
import profile_icon from '../assets/images/profile_icon.png';

function Profile() {
    return (
        <Box id="profile" sx={{ marginTop: 4, marginBottom:15 }}>
            <Typography variant="h3" gutterBottom sx={{ textAlign: 'center' }}>
                Profile
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', }}>
                {/* プロフィール画像 */}
                <Box
                    component="img"
                    src={profile_icon}
                    alt="サンセビア ロゴ"
                    sx={{ width: 300, minWidth: 300, height: 300, marginRight: 4 }}
                />
                
                {/* プロフィールのテキスト */}
                <Typography variant="body1" component="div" sx={{ maxWidth: 600 }}>
                    こんにちは。サンセビアのWebエンジニア、かつよし（勝誉）です。<br />
                    Web業界でのキャリアは10年以上になります。<br /><br />
                    
                    <strong>主な専門分野:</strong><br />
                    - フロントエンド開発（React、TypeScript、Material-UIを用いたインタラクティブでレスポンシブなUIの実装）<br />
                    - バックエンド開発（Django、Flask、Node.jsを使用した安定したサーバーサイドアーキテクチャの構築）<br />
                    - AIと機械学習の導入（ChatGPTをはじめとするAI技術を駆使し、ユーザー体験の最適化を図っています）<br />
                    - セキュリティ対策（JWT認証、SSLの実装によるセキュアなサービス提供）<br /><br />

                    <strong>今後の目標:</strong><br />
                    今後は、より多くのAIを活用したプロジェクトに携わり、さらに幅広い分野で技術革新を推進したいと考えています。
                    また、オープンソースプロジェクトへの貢献も視野に入れており、技術コミュニティとの交流を深めていく予定です。<br /><br />

                    Webサービスの開発を通じて、技術革新と人々の生活向上に貢献することを目指しています。<br />
                    何かお手伝いできることがありましたら、ぜひお気軽にご連絡ください。
                </Typography>
            </Box>
        </Box>
    );
}

export default Profile;
