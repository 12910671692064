import React from 'react';
import { AppBar, Toolbar, Typography, Box, Button } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import Link from '@mui/material/Link';


function Footer() {
    return (
        <>
            <Box 
                sx={{
                    width: '100%',
                    height: '20vh',
                    backgroundColor: 'black',
                    color: 'white',
                    paddingTop: 2,
                }}
            >
                <AppBar position="static" sx={{ backgroundColor: 'black' }}>
                    <Toolbar sx={{ justifyContent: 'center' }}>
                        <ScrollLink to="profile" smooth={true} duration={500} offset={-70}>
                            <Link sx={{ cursor: 'pointer', color: 'white' }}>
                                <Button color="inherit">Profile</Button>
                            </Link>
                        </ScrollLink>
                        <ScrollLink to="skill" smooth={true} duration={500} offset={-70}>
                            <Link sx={{ cursor: 'pointer', color: 'white' }}>
                                <Button color="inherit">Skill</Button>
                            </Link>
                        </ScrollLink>
                        <ScrollLink to="service" smooth={true} duration={500} offset={-70}>
                            <Link sx={{ cursor: 'pointer', color: 'white' }}>
                                <Button color="inherit">Service</Button>
                            </Link>
                        </ScrollLink>
                        <ScrollLink to="contact" smooth={true} duration={500} offset={-70}>
                            <Link sx={{ cursor: 'pointer', color: 'white' }}>
                                <Button color="inherit">Contact</Button>
                            </Link>
                        </ScrollLink>
                    </Toolbar>
                </AppBar>
                <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                    <Typography variant="body1" component="p">
                        © 2024 サンセビア
                    </Typography>
                </Box>
            </Box>
        </>
    );
}

export default Footer;
