import React from 'react';
import { Box } from '@mui/material';
import Header from '../../components/Header';
import MainContent from '../../components/MainContent';

function Home() {
    return (
    <div>
        <MainContent />
    </div>
    )
}

export default Home;
