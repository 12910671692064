import { Typography } from '@mui/material';
import React from 'react';
import { Box, Divider } from '@mui/material';


function Career() {
    const careerData = [
        'SNSゲーム開発',
        '病院の血流モニタソフト開発',
        '透析管理システム開発',
        '固定資産システムの開発',
        'カルテソフト開発',
        '結婚式管理システム開発',
        '福祉用具事業支援システム開発',
    ];
    const skills = ['React', 'TypeScript', 'JavaScript', 'CSS', 'Figma', 'Adobe XD'];

    return (
        <Box sx={{ padingg: '20px' }}>
            <Typography variant='h4' gutterBottom>
                私の経歴
            </Typography>

            {/* タイムライン形式の職歴表示 */}
            {careerData.map((career, index) => (
                <Box key={index} sx={{ marginBottom: '20px' }}>
                    <Typography variant='h6'>
                        ・{career}
                    </Typography>
                </Box>
            ))}
            </Box>
    );
}

export default Career;
