import React, { useState, useEffect } from 'react';
import { Box, Container, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import logo from '../../src/assets/images/logo.png';
import backImage from '../../src/assets/images/back_image.jpg';
import Profile from './Profile';
import Skill from './Skill';
import Contact from './Contact';
import Service from './Service';
import Footer from './Footer';
import { Link as ScrollLink } from 'react-scroll';
import Link from '@mui/material/Link';

function MainContent() {
    const [headerOpacity, setHeaderOpacity] = useState(0.2);

    // スクロール時に透明度を変化させる
    useEffect(() => {
        const handleScroll = () => {
            const opacity = Math.min(0.2 + window.scrollY / 200, 0.8); // 最大0.8まで透明度を変更
            setHeaderOpacity(opacity);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Box
            id="top"
            sx={{
                backgroundImage: `url(${backImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '60vh',
                width: '100%',
                position: 'relative',
            }}
        >
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: `rgba(0, 0, 0, ${headerOpacity})`,
                    backdropFilter: 'blur(5px)',
                    transition: 'background-color 0.3s',
                }}
            >
                <Toolbar 
                    sx={{ 
                        justifyContent: 'space-between',width: '100%',
                        maxWidth: '1280px',
                        margin: '0 auto' }}>
                    <ScrollLink to="top" smooth={true} duration={500} offset={-70}>
                        <Link sx={{ cursor: 'pointer', color: 'white', textDecoration: 'none' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box
                                    component="img"
                                    src={logo}
                                    alt="サンセビア ロゴ"
                                    sx={{ width: 70, height: 70, marginRight: 1 }}
                                />
                                <Typography variant="h6" component="div" sx={{ color: 'white' }}>
                                    サンセビア
                                </Typography>
                            </Box>
                        </Link>
                    </ScrollLink>
                    <Box sx={{ padding: '0 20px 0 0px' }}>
                        <ScrollLink to="profile" smooth={true} duration={500} offset={-70}>
                            <Link sx={{ cursor: 'pointer', color: 'white' }}>
                                <Button sx={{ fontSize: '20px', margin:'0 15px 0 0', textTransform: 'none' }} color="inherit">Profile</Button>
                            </Link>
                        </ScrollLink>
                        <ScrollLink to="skill" smooth={true} duration={500} offset={-70}>
                            <Link sx={{ cursor: 'pointer', color: 'white' }}>
                                <Button sx={{ fontSize: '20px', margin:'0 15px 0 0', textTransform: 'none'   }} color="inherit">Skill</Button>
                            </Link>
                        </ScrollLink>
                        <ScrollLink to="service" smooth={true} duration={500} offset={-70}>
                            <Link sx={{ cursor: 'pointer', color: 'white' }}>
                                <Button sx={{ fontSize: '20px', margin:'0 15px 0 0', textTransform: 'none'   }} color="inherit">Service</Button>
                            </Link>
                        </ScrollLink>
                        <ScrollLink to="contact" smooth={true} duration={500} offset={-70}>
                            <Link sx={{ cursor: 'pointer', color: 'white' }}>
                                <Button sx={{ fontSize: '20px', textTransform: 'none'  }} color="inherit">Contact</Button>
                            </Link>
                        </ScrollLink>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* 背景と重なるエリア */}
            <Box
                sx={{
                    height: '60vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="h4" color="white">
                    {/* 背景とテキストが一体となったエリア */}
                </Typography>
            </Box>
            
            {/* プロフィールセクション */}
            <Box sx={{ backgroundColor: '#f5f5f5', padding: '40px 0' }}>
                <Container maxWidth="lg">
                    <Profile />
                </Container>
            </Box>

            {/* スキルセクション */}
            <Box sx={{ backgroundColor: '#d1eaf3', padding: '40px 0' }}>
                <Container maxWidth="lg">
                    <Skill />
                </Container>
            </Box>

            {/* サービスセクション */}
            <Box sx={{ backgroundColor: '#fff3e0', padding: '40px 0' }}>
                <Container maxWidth="lg">
                    <Service />
                </Container>
            </Box>

            {/* お問い合わせセクション */}
            <Box sx={{ backgroundColor: '#f3e5f5', padding: '40px 0' }}>
                <Container maxWidth="lg">
                    <Contact />
                </Container>
            </Box>

            <Footer />
        </Box>
    );
}

export default MainContent;
