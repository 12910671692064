import React, { useState, FormEvent,ChangeEvent } from 'react';
import axios from 'axios';
import { CharacterDic } from '../constants/consts';
import {Bar} from 'react-chartjs-2';

interface Message {
    role: 'user' | 'assistant';
    content: string;
}

function FrameworkHorizontalBarChart() {
    const data = {
        labels: ['C#.NET', 'Django', 'Flask','React'],
        datasets: [
            {
                label: '経験年数',
                data: [5, 3, 3, 2],
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };
    const options = {
        indexAxis: 'y' as const, 
        scales: {
            x: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                position: 'top' as const,
            },
        title: {
                display: true,
                text: 'フレームワーク/DBの経験年数',
            },
        },
    };

    return (
        <Bar data={data} options={options} />
    );
};

export default FrameworkHorizontalBarChart;
