import { Box, Typography } from '@mui/material';
import ChatAdviser from './Service/ChatAdviser';

function Service() {
    return (
        <Box id="service" sx={{ textAlign: 'center', marginBottom:20 }}>
            <Typography variant="h3" gutterBottom>
            Service
            </Typography>

            <ChatAdviser />
        </Box>
    );
}

export default Service;
