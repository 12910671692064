import React from 'react';
import logo from './logo.svg';
import './App.css';
import Layout from './components/Layout';
import { Route } from 'react-router-dom';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './app/routes/Home';
import About from './app/routes/About';
import Career from './app/routes/Career';
import Affiliate from './components/Service/Affiliate';
import Skill from './components/Skill';
import { Routes as RouteConst } from './constants/routes';
import ChatAdviser from './components/Service/ChatAdviser';
import Contact from './components/Contact';

function App() {
  return (
    <Router>
        <Routes>
          <Route path={ RouteConst.Home} element={<Home />} />
          <Route path={ RouteConst.About} element={<About />} />
          <Route path={ RouteConst.ChatAdviser} element={<ChatAdviser />} />
          <Route path={ RouteConst.Carrier} element={<Career />} />
          <Route path={ RouteConst.Skill} element={<Skill />} />
          <Route path={ RouteConst.Contact} element={<Contact />} />
        </Routes>
    </Router>
  );
}

export default App;
