import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function AdditionalExperienceChart() {
    const data = {
        labels: ['Windows', 'Azure', 'VPS', 'Visual Studio'],
        datasets: 
        [
            {
                label: '経験年数',
                data: [10, 3, 5, 8],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    
    const options = {
        indexAxis: 'y' as const,  // 水平棒グラフに設定
        scales: {
            x: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'その他のスキル経験年数',
            },
        },
    };
    
    return <Bar data={data} options={options} />;
}

export default AdditionalExperienceChart;
