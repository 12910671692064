import React, { useState, FormEvent,ChangeEvent } from 'react';
import axios from 'axios';
import { CharacterDic } from '../../constants/consts';
import { Button } from '@mui/material';

interface Message {
    role: 'user' | 'assistant';
    content: string;
}

function ChatAdviser() {
    const [prompt, setPrompt] = useState<string>('');
    const [messages, setMessages] = useState<Message[]>([]);
    const [selectedAssistant, setSelectedAssistant] = useState("0");
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState('');

    // アシスタントの選択を変更
    const handleAssistantChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedAssistant(e.target.value);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        try {
            if(!prompt) {
                setStatus('質問を入力してください。');
                return;
            }

            setIsLoading(true);
            const response = await axios.post<{ answer: string}>('http://localhost:8000/api/call_chatgpt/', 
                { prompt, selectedAssistant }, 
                { withCredentials: true}
            );
            const answer = response.data.answer;
            setStatus('');
            setMessages([...messages, { role: 'user', content: prompt }, { role: 'assistant', content: answer }]);
            setPrompt('');

        } catch (error: any) {
            if (error.response) {
                const errorMessage = error.response.data?.error || 'エラーが発生しました。';
                setStatus(errorMessage);
            }
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="chat-container">
            <h2>相談サービス</h2>
            <p>落ち込んでいる時、相談相手になります。<br />ChatGPTを使っています。</p>
            <select value={selectedAssistant} onChange={handleAssistantChange} style={{ marginBottom: '10px', padding: '5px' }}>
                {Object.entries(CharacterDic).map(([key, value]) => (
                    <option key={key} value={key}>
                        {value}
                    </option>
                ))}
            </select>
            <div className="chat-box">
                {messages.map((msg, index) => (
                    <div key={index} className={`message ${msg.role}`}>
                        <strong>{msg.role === 'user' ? 'You' : 'ChatGPT'}:</strong> {msg.content}
                    </div>
                ))}
            </div>
            <form onSubmit={handleSubmit} className="input-form">
                <input
                    type="text"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    placeholder="落ち込んだことを教えてください"
                    required
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                >
                    {isLoading ? '送信中...' : '送信'}
                </Button>
            </form>
            <p>{status}</p>
        </div>
    );
};

export default ChatAdviser;
