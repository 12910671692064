import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { Box, Divider } from '@mui/material';
import axios from 'axios';
import { Input, InputAdornment, FormControl, InputLabel } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person'; // これを追加
import TextField from '@mui/material/TextField';
import EmailIcon from '@mui/icons-material/Email';
import TitleIcon from '@mui/icons-material/Title';
import MessageIcon from '@mui/icons-material/Message';
import Button from '@mui/material/Button';

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const isValidEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const handleSubmit = async (e:any) => {
        e.preventDefault();

        if(!isValidEmail(email)) {
            setStatus('メールアドレスが無効です。');
            return;
        }

        if(!name || !subject || !message) {
            setStatus('全てのフィールドを入力してください。');
            return;
        }

        setIsLoading(true);

        try {
            const response = await axios.post('http://localhost:8000/api/send_contact_email/', {
                name,
                email,
                subject,
                message,
            });

            // サーバーからのレスポンスに基づいてステータスを更新
            if(response.data.success) {
                setStatus('メールが送信されました。');
                setName('');
                setEmail('');
                setSubject('');
                setMessage('');
            }
            else {
                setStatus(`メールの送信に失敗しました: ${response.data.message}`);
            }
        }
        catch (error) {
            if(axios.isAxiosError(error) && error.response) {
                setStatus(`サーバーエラー:${error.response.data.message}`);
            }
            else {
                setStatus('ネットワークエラーが発生しました。');
            }
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <Box id="contact" sx={{  }}>
            <Typography sx={{textAlign: 'center' }} variant='h3' gutterBottom>
                Contact
            </Typography>
            <Divider />

            {/* お問い合わせフォーム */}
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
                    <TextField
                        label="お名前"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            },
                        }}
                        variant="outlined"
                        required
                        fullWidth
                        margin="normal"
                    />
                </FormControl>
                <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
                    <TextField
                        label="メールアドレス"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            },
                        }}
                        variant="outlined"
                        required
                        fullWidth
                        margin="normal"
                    />
                </FormControl>
                
                <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
                    <TextField
                        label="件名"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <TitleIcon />
                                    </InputAdornment>
                                ),
                            },
                        }}
                        variant="outlined"
                        required
                        fullWidth
                        margin="normal"
                    />
                </FormControl>
                <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
                    <TextField
                        label="メッセージ"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MessageIcon />
                                    </InputAdornment>
                                ),
                            },
                        }}
                        variant="outlined"
                        multiline
                        rows={4}
                        required
                        fullWidth
                        margin="normal"
                    />
                </FormControl>
                    
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    fullWidth
                    sx={{ marginTop: 2 }}
                >
                    {isLoading ? '送信中...' : '送信'}
                </Button>
            </form>
            {status && (
                <Typography variant="body1" sx={{ color: status.includes('送信されました') ? 'green' : 'red', marginTop: 2 }}>
                    {status}
                </Typography>
            )}
        </Box>
    );
}

export default Contact;
